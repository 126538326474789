<template>
<h1>Events</h1>

<hr>

<img v-bind:src="'pass_it_on.png'" alt="Pass the Message Flyer 2025">

<hr>

</template>

<style scoped>
 img {
   width: 80vw;
   max-width: 800px
 }

h2, h3{ 
  text-align: center;
}

a {
  font-size: 24px;
}

button {
  width: 75%;
  height: 50px;
  font-size: large;
}

</style>

<hr>
